
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ref, defineComponent, onMounted, watch, computed } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";

import { useRouter } from "vue-router";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
//import { formatDateTimePHP } from "@/core/helpers/date";
import SubmitButton from "@/components/SubmitButton.vue";

import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "EditProfile",

  components: { SubmitButton },

  setup() {
    const store = useStore();
    const router = useRouter();

    let countries = computed(() => {
      return store.getters[Modules.MASTER + "getCountries"];
    });

    let professions = computed(() => {
      return store.getters[Modules.MASTER + "getProfessions"];
    });

    let timezones = computed(() => {
      return store.getters[Modules.MASTER + "getTimezones"];
    });

    let userProfile = computed(() => {
      return store.getters.currentUser;
    });

    let currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const submitButton = ref<HTMLButtonElement | null>(null);

    const imageURL = ref();

    const schema = yup.object({
      name: yup.string().max(64).label("Full Name"),
      company_name: yup.string().max(255).label("Company Name"),
      //linkedin_url: yup.string().max(255).label("Company Name"),
      linkedin_url: yup.string().url().label("LinkedIn URL"),
      country_id: yup.string().label("Country"),
      profession_id: yup.string().label("Profession"),
      timezone: yup.string().label("Timezone"),
      photo: yup.string().label("Photo"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        //id:"";
        name: "",
        company_name: "",
        linkedin_url: "",
        country_id: "",
        profession_id: "",
        timezone: "",
        photo: "",
        // photo1: "",
      },
      validationSchema: schema,
    });
    //const { value: id } = useField("id");
    const { value: name } = useField("name");
    const { value: company_name } = useField("company_name");
    const { value: linkedin_url } = useField("linkedin_url");
    const { value: country_id } = useField("country_id");
    const { value: profession_id } = useField("profession_id");
    const { value: timezone } = useField("timezone");
    const { value: photo } = useField("photo");

    const onSubmit = handleSubmit((values) => {
      console.log(values);

      let formData = new FormData();

      formData.append("_method", "put");
      formData.append("name", values.name!);
      formData.append("company_name", values.company_name!);
      formData.append("linkedin_url", values.linkedin_url!);
      formData.append("country_id", values.country_id!);
      formData.append("profession_id", values.profession_id!);
      formData.append("timezone", values.timezone!);

      formData.append("photo", values.photo!);

      return new Promise<void>((resolve) => {
        store
          .dispatch(Actions.UPDATE_PROFILE, formData)

          .then(() => {
            Swal.fire({
              text: store.getters["getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              store.dispatch(Actions.FETCH_PROFILE);
              router.push({ name: "profile" });
              //this.router.go()
              //store.getters[Modules.EVENT + "getEvents"];
            });
          })
          .catch(() => {
            setErrors(store.getters["getErrors"]);

            Swal.fire({
              //text: store.getters.getErrors[error],
              text: "Failed to Update an Profile. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    function chooseImage(event) {
      if (event.target.files.length === 0) {
        photo.value = "";
        imageURL.value = "";
        return;
      }
      photo.value = event.target.files[0];
    }

    watch(photo, (photo) => {
      if (!(photo instanceof File)) {
        return;
      }
      let fileReader = new FileReader();

      fileReader.readAsDataURL(photo);
      fileReader.onload = (event: Event) => {
        imageURL.value = fileReader.result;
      };
    });

    onMounted(() => {
      setCurrentProfile();
      setCurrentPageTitle("Edit Profile");
      // router.push({ name: "kt-aside-primary" });
    });

    let setCurrentProfile = () => {
      if (Object.keys(userProfile.value).length !== 0) {
        //console.log("data",userProfile.value.verified);
        console.log(currentUser.value);
        name.value = userProfile.value.name;
        imageURL.value = userProfile.value.photo;

        // alert(document.getElementById('myName').value)
        //currentUser.photo=currentUser.value.photo;
        //currentUser.photo= userProfile.value.photo;

        if (
          Object.prototype.hasOwnProperty.call(userProfile.value, "profile")
        ) {
          let profile = userProfile.value.profile;

          company_name.value = profile.company_name ?? "";
          linkedin_url.value = profile.linkedin_url ?? "";
          if (Object.prototype.hasOwnProperty.call(profile, "country")) {
            country_id.value =
              profile.country != null ? profile.country.id : "";
          }
          if (Object.prototype.hasOwnProperty.call(profile, "profession")) {
            profession_id.value =
              profile.profession != null ? profile.profession.id : "";
          }
        }
        timezone.value = userProfile.value.timezone;
      }
    };

    watch(userProfile, (value) => {
      setCurrentProfile();
    });

    // Send request
    store.dispatch(Actions.FETCH_PROFILE);

    return {
      countries,
      professions,
      timezones,
      userProfile,
      submitButton,
      meta,
      errors,
      onSubmit,
      isSubmitting,
      schema,
      name,
      company_name,
      linkedin_url,
      country_id,
      profession_id,
      timezone,
      currentUser,
      //photo1,
      imageURL,

      chooseImage,
    };
  },
});
